video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -2;
}

.break-container {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    height: 40vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}