@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.hero-container {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}

.hero-container>h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
    animation: fadein;
    animation-duration: 2s;

}

.hero-container>.author {
    opacity: 0;
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
        'Lucida Sans', Arial, sans-serif;
    text-align: center;
    animation: fadein;
    animation-delay: 1.5s;
    animation-duration: 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.hero-container>p {
    opacity: 0;
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
        'Lucida Sans', Arial, sans-serif;
    text-align: center;
    animation: fadein;
    animation-delay: 4.5s;
    animation-duration: 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.hero-btns {
    margin-top: 32px;
}

.hero-btns .btn {
    margin: 6px;
}

.fa-wand-magic-sparkles {
    margin-left: 6px;
}

@media screen and (max-width: 960px) {
    .hero-container>h1 {
        font-size: 70px;
        margin-top: -20px;
    }
}

@media screen and (max-width: 768px) {
    .hero-container>h1 {
        font-size: 50px;
        margin-top: -20px;
    }

    .hero-container>p {
        font-size: 30px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }
}