.dad {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    background: linear-gradient(to bottom left, #242424, #111, #333);

}

.about-sitehart-container>h1 {
    color: white;
    font-size: 60px;
    padding-top: 20px;
}

.about-sitehart-content-container {
    display: flex;
    flex-flow: row;
    align-items: center;


}

hr {
    border-top: 3px solid #bbb;
    width: 100%;
}

.btn-div {
    margin-top: 20px;
    color: white;
    font-size: 24px;
    margin-bottom: 20px;
}

.or-div {
    color: white;
    font-size: 24px;
}

.btn-div .btn {
    margin: 6px;
    font-size: 24px;
}

hr {
    border-top: 3px solid #bbb;
    width: 80%;
}



.sitehart-logo {
    margin-top: 30px;
    width: 75%;
    height: auto;
    margin-bottom: 30px;

}




.intro-paragraph2 {
    font-size: 24px;
    color: white;
    padding-top: 30px;
    padding-bottom: 20px;
    text-align: left;
}

.content-gap {
    height: 10vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    background-color: none;

}

.solid2 {
    border-top: 3px solid #bbb;
    width: 100%;
}


@media screen and (max-width: 960px) {
    .about-me-content-container {
        flex-flow: column;
        flex-direction: column-reverse;
    }

    .mugshot-container {
        padding-top: 25px;
    }


    .intro-paragraph2 {
        text-align: left;
    }

    .about-sitehart-container>h1 {
        font-size: 55px;
    }

    .sitehart-logo {
        height: auto;
        width: 100%;
    }



}

@media screen and (max-width: 768px) {}