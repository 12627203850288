.coming-soon-container {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    text-align: center;
}

.coming-soon-content {

    width: 80%;
}

.coming-soon-content p {

    font-size: 40px;
}

.coming-soon-content h1 {

    padding: 40px;
    padding-top: 0px;
}

.coming-soon-content img {

    height: 300px;
    width: 300px;
    animation: wobble 3s infinite;
}

.coming-soon-content .btn--large {

    margin-top: 30px;
    margin-bottom: 30px;
}

@media screen and (max-width: 960px) {
    .coming-soon-content>h1 {
        font-size: 70px;
        margin-top: -20px;
    }
}

@media screen and (max-width: 768px) {
    .coming-soon-content>h1 {
        font-size: 50px;
        margin-top: -20px;
    }

    .coming-soon-content>p {
        font-size: 30px;
    }

}