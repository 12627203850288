.space-background {
    height: 100%;
    background-repeat: repeat;
}

.buttons-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;
}

.button {
    font-size: 20px;
    width: 20%;
    background-color: transparent;
    color: white;
    border: none;
    text-align: left;
    margin: auto;
    text-align: center;
    border-radius: 50%;
    padding: 20px;
    position: relative;
    height: 100px;
    width: 100px;
    left: 0;
    top: 0;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    font-weight: bold;


}

.button:hover {
    cursor: pointer;
    text-decoration: underline;
    transform: scale(1.2);


}

p {
    font-size: 24px;
}

.items-container {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: left;
    margin-top: 50px;
}

.item {
    width: 33%;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
    z-index: 20;
    margin: 1rem 0;
    padding-right: 50px;
}

.projects-div {
    width: 80%;

}

.parent-div {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
}

.item-content>Button {
    width: 100%;
}

.project-card-image {
    border-radius: 500%;
    max-width: 75%;

}

.web-design-image {
    border-radius: 500%;
    max-width: 75%;
    filter: drop-shadow(0 0 0.75rem blue);
}

.game-design-image {
    border-radius: 500%;
    max-width: 75%;
    filter: drop-shadow(0 0 0.75rem lime);
}

.csharp-image {
    border-radius: 500%;
    max-width: 75%;
    filter: drop-shadow(0 0 0.75rem magenta);
}

.java-image {
    border-radius: 500%;
    max-width: 75%;
    filter: drop-shadow(0 0 0.75rem orange);
}

.planet-image-container {
    justify-content: center;
    display: flex;
    margin-bottom: 20px;

}

.project-category {
    font-size: 16px;
}

.fa-tag {
    margin-right: 6px;
}

.fa-rocket {
    margin-left: 10px;
}

.item-details-container {
    padding: 10px;
}

.project-description {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.categories-header {
    width: 80%;
    justify-content: left;
    display: flex;
}

.categories-header>p {
    font-size: 32px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.buttons-container .active {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    top: 0;
    transform: scale(1.2);
    filter: drop-shadow(0 0 0.75rem gray);

}

.not-selected {
    filter: grayscale(100%);
    /* Applies 100% grayscale filter */

}

.not-selected:hover {
    animation: pulse3 1s infinite ease-in-out;
}

.item-details-container .btn--medium {
    width: 100%;
    margin-top: 20px;
}

.btn--medium:hover>.fa-rocket {
    animation: gelatine 2s ease infinite;
}

@keyframes spin2 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: inherit;
    background-size: cover;
    background-position: center;
    animation: spin 2s infinite linear;
    border-radius: 50%;
    z-index: -1;

    animation-name: rotate;
    animation-duration: 12s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 124s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;



}

@keyframes rotate {
    from {
        background-position: 0px 0px;
    }

    to {
        background-position: 1024px 0px;
    }
}

@-webkit-keyframes rotate {
    from {
        background-position: 0px 0px;
    }

    to {
        background-position: 1024px 0px;
    }
}


@keyframes pulse3 {
    from {
        transform: scale(1.0);
    }

    to {
        transform: scale(1.2);
    }
}

@keyframes bounce {
    70% {
        transform: translateY(0%);
    }

    80% {
        transform: translateY(-15%);
    }

    90% {
        transform: translateY(0%);
    }

    95% {
        transform: translateY(-7%);
    }

    97% {
        transform: translateY(0%);
    }

    99% {
        transform: translateY(-3%);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes roll-out {
    0% {
        opacity: 1;
        transform: translateX(0px) rotate(0deg);
    }

    100% {
        opacity: 0;
        transform: translateX(100%) rotate(120deg);
    }
}

@keyframes gelatine {

    from,
    to {
        transform: scale(1, 1);
    }

    25% {
        transform: scale(0.9, 1.1);
    }

    50% {
        transform: scale(1.1, 0.9);
    }

    75% {
        transform: scale(0.95, 1.05);
    }
}

/* Background images for different categories */
.all-filter {
    background-image: url(/public/Images/AllPlanet.png);
}

.web-design-filter {
    background-image: url(/public/Images/WebDesignPlanet.png);
}

.csharp-filter {
    background-image: url(/public/Images/CSharpPlanet.png);
}

.java-filter {
    background-image: url(/public/Images/JavaPlanet.png);
}

.game-design-filter {
    background-image: url(/public/Images/GameDesignPlanet.png);
}

@media screen and (max-width: 960px) {

    .buttons-container {
        /* Wrap buttons to multiple lines on small screens */
        gap: 10px;


    }

    .buttons-container .button {
        width: 80%;
        background-image: none;
        border-radius: 0px;
        margin: 10px;
        border: 1px solid white;
    }

    .buttons-container .button:hover {
        background-color: rgba(255, 255, 255, 0.5);
        color: black;
    }

    .buttons-container .active {
        background-color: rgba(255, 255, 255, 0.8);
        color: black;

    }


    .not-selected:hover {
        animation: none;

    }

    .categories-header {
        justify-content: center;
    }

    .items-container {
        flex-direction: column;
    }

    .item {
        width: 100%;
        padding-right: 0px;
    }
}

@media screen and (max-width: 768px) {

    .buttons-container .button:hover {
        background-color: rgba(255, 255, 255, 0.8);
    }


}