.about-me-container {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    background: linear-gradient(to bottom left, #242424, #111, #333);
}

.about-me-container>h1 {
    color: white;
    font-size: 60px;
    padding-top: 20px;
}

.about-me-content-container {
    display: flex;
    flex-flow: row;
    align-items: center;
    max-width: 80%;


}

.about-me-personal-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 80%;
    width: 80%;
    padding-bottom: 50px;
    padding-top: 50px;
}

.about-sitehart-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 80%;
}

hr {
    border-top: 3px solid #bbb;
    width: 80%;
}

.mugshot {
    max-width: 500px;
    height: 40vh;
    padding-top: 10px;
}



.funny-dino-image {
    width: 100%;
    max-width: 20000px;
    height: 80vh;
    padding-bottom: 40px;
}

.intro-paragraph {
    font-size: 24px;
    color: white;
    padding-top: 30px;
    padding-bottom: 20px;
    text-align: left;
}

.intro-paragraph2 {
    font-size: 24px;
    color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
}

.content-gap {
    height: 10vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    background-color: none;

}




@media screen and (max-width: 960px) {
    .about-me-content-container {
        flex-flow: column;
        flex-direction: column-reverse;
    }

    .mugshot-container {
        padding-top: 25px;
    }

    .intro-paragraph {
        padding-top: 20px;
    }

}

@media screen and (max-width: 768px) {
    .funny-dino-image {
        height: 40vh;
    }
}