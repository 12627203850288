@keyframes hithere {
    30% {
        transform: scale(1.2);
    }

    40%,
    60% {
        transform: rotate(-20deg) scale(1.2);
    }

    50% {
        transform: rotate(20deg) scale(1.2);
    }

    70% {
        transform: rotate(0deg) scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}


:root {
    --primary: #fff;
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
    white-space: nowrap;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--medium:hover,
.btn--large:hover {
    background: #fff;
    color: #242424;
    transition: all 0.3s ease-out;
}

.btn--large:hover .fa-wand-magic-sparkles {
    color: #FF6A00;
    animation: hithere 1s ease infinite;
}

.btn--large:hover .fa-arrow-right {
    color: #FF6A00;
    animation: hithere 1s ease infinite;
}